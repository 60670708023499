<form *ngIf='!loading && company && tierForm, else isLoading' [formGroup]="tierForm">
  <div class='row mt--md row-start'>
    <div *ngFor="let pricingTier of pricingTiers" class='column--12 column-md--4 with-gutter'>
      <div class='pricing-tier' [ngClass]="selectedPricingTier === pricingTier.tier ? 'selected' : ''" (click)='selectPricingTier(pricingTier.tier)'>
        <div class='title-container' [ngClass]="pricingTier.tier">{{ pricingTier.tierName }}</div>
        <div class='options-container'>
          <div class='margin-title text--sm'>Margin level</div>
          <div class='margin' *ngIf="pricingTier.margin.length === 1">{{ pricingTier.margin[0] }}%</div>

          <mat-form-field *ngIf="pricingTier.margin.length > 1" class='full-width'>
            <mat-select formControlName="pricingCustom" name='pricingCustom'>
              <mat-option *ngFor='let margin of pricingTier.margin' [value]="margin">{{ margin }}%</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class='row mt--md'>
    <div class='column--12'>
      <button mat-flat-button color='primary' type='submit' [disabled]="!canEdit || tierForm.invalid || loading" (click)='updatePricingTier()'>Update</button>
    </div>
  </div>
</form>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
