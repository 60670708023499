import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss'],
})
export class ReportCardComponent {
  @Input() loadingData: boolean = true;
  @Input() canDownloadData: boolean = false;
  @Input() loadingCard: boolean = true;
  @Input() loadingCardFailed: boolean = false;
  @Input() iconClass: string = '';
  @Input() titleClass: string = '';
  @Input() cardTitle: string = '';
  @Input() totalStat: string = '';
  @Input() isCurrency: boolean = false;
  @Input() subStatsTemplate: TemplateRef<any>;
  @Output() refresh: EventEmitter<boolean> = new EventEmitter();
  @Output() downloadData: EventEmitter<boolean> = new EventEmitter();

  constructor() {}
}
