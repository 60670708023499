import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { first } from 'rxjs';
import { MatAccordion } from '@angular/material/expansion';
import { FormBuilder, FormGroup } from '@angular/forms';
import atLeastOneRequired from '@app/shared/validators/at-least-one-required.validator';
import { LoggerService } from '@app/shared/services/logger.service';
import { Company, User, UsersByCompany } from './users.model';
import * as _ from 'lodash';
import { Roles, ShippingProviderNames } from '@app/shared/constants';
import { HelpersService } from '@app/shared/services/helpers.service';
import { SessionStorageService } from 'ngx-webstorage';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { PageEvent } from '@angular/material/paginator';
import { CompanyService } from '@app/shared/services/company.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
  @ViewChild(MatAccordion) searchOptions: MatAccordion | undefined;

  public user: any;
  public loading: boolean = true;
  public noUsersReturned: boolean = false;
  public isExpanded: boolean = false;
  public filterForm: FormGroup | undefined;
  public users: User[] = [];
  public usersByCompany: UsersByCompany[] = [];
  public currentPage: number = 0;
  public itemsPerPage: number = 5;
  public itemsPerPageOptions: number[] = [5, 10, 25];
  public paginatedUsersByCompany: UsersByCompany[] = [];
  public paginatedUsers: User[] = [];
  public displayedColumns: string[] = ['name', 'users', 'tier', 'pickup', 'billingType'];
  public displayedExpandedColumns: string[] = ['customerName', 'email', 'role', 'login'];
  public columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];
  public expandedElement: any;

  public readonly userRolesDropdown = [
    { id: '', text: 'Any' },
    { id: 'U', text: 'User' },
    { id: 'A', text: 'Admin' },
    { id: 'O', text: 'Owner' },
    { id: 'M', text: 'Customer Support' },
    { id: 'S', text: 'Support User' },
  ];

  public readonly fraudulentDropdown = [
    { id: '', text: 'Any' },
    { id: false, text: 'No' },
    { id: true, text: 'Yes' },
  ];

  public readonly billingTypesDropdown = [
    { id: '', text: 'Any' },
    { id: 'M', text: 'Machool account' },
    { id: 'C', text: 'Credit card' },
  ];

  public readonly pricingTiersDropdown = [
    { id: '', text: 'Any' },
    { id: 'B', text: 'Bronze' },
    { id: 'S', text: 'Silver' },
    { id: 'G', text: 'Gold' },
    { id: 'C', text: 'Custom' },
    { id: 'Q', text: 'CQCD' },
    { id: 'E', text: 'Employee' },
  ];
  public salesReps = [];
  public salesRepsDropdown = [];
  public readonly WARNING_LEVEL = 0.9;
  public canEdit: boolean = false;
  public canViewLogin: boolean = false;
  public canViewSettings: boolean = false;
  public canViewRecon: boolean = false;
  public canViewExtendedInfo: boolean = false;
  public CDN_URL: string = environment.CDN_URL;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private helpersService: HelpersService,
    private companyService: CompanyService,
    private logger: LoggerService,
    private sessionStorageService: SessionStorageService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loading = true;
    this.createForm();

    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_USERS])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.companyService.getSalesReps().subscribe((salesReps) => {
              this.logger.log('Configure Sales - Get Sales Reps', salesReps);
              this.salesReps = salesReps;
              this.salesRepsDropdown = salesReps.map((salesRep) => {
                return {
                  id: salesRep.id,
                  text: `${salesRep.firstName} ${salesRep.lastName}`,
                };
              });
              this.salesRepsDropdown = [{ id: '', text: 'Any' }, ...this.salesRepsDropdown];
              const storedFilters = this.sessionStorageService.retrieve('csSearchParams');
              this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_USERS]);
              this.canViewLogin = this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_USERS_LOGIN]);
              this.canViewSettings = this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_USERS_SETTINGS]);
              this.canViewRecon = this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_RECONCILIATION]);
              // TODO - Should this be its own scope?
              this.canViewExtendedInfo = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_USERS]);

              if (storedFilters) {
                this.sessionStorageService.clear('settingsCompany');
                this.filterForm.patchValue(storedFilters.filters);
                this.currentPage = storedFilters.page;
                this.search();
              }
              this.loading = false;
            });
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
  }

  public togglePanel() {
    this.isExpanded = !this.isExpanded;
  }

  public reset() {
    this.createForm();
    this.noUsersReturned = false;
    this.sessionStorageService.clear('csSearchParams');
    this.paginatedUsers = [];
  }

  public search(reset: boolean = true) {
    this.currentPage = reset ? 1 : this.currentPage;
    const filterValues = (this.filterForm || {}).value;
    this.noUsersReturned = false;
    this.userService.getFromAll(filterValues).subscribe((accounts) => {
      this.logger.log('Users Lookup GET accounts', accounts);
      this.users = accounts;
      if (this.users.length === 0) {
        this.noUsersReturned = true;
      }
      this.sessionStorageService.clear('settingsCompany');
      this.cleanUsers();
      this.setupGroupedUsers();
      this.pageChanged({
        pageIndex: 0,
        pageSize: this.itemsPerPage,
        length: this.usersByCompany.length,
      });
    });
  }

  // TODO - figure out a sane way to log someone it to app-cli from this portal
  public loginAs(user: any) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      data: {
        title: 'Login as User',
        content: `Please confirm that you want to login as: ${user.firstName} ${user.lastName}`,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('returned result', result);
    });
  }

  public verifyAccount(user: any) {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      data: {
        title: 'Verify Account',
        content: `Please confirm that you want to verify: ${user.email}`,
      },
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.userService.verifyUser({ email: user.email }).subscribe((user: any) => {
          this.logger.log('User account Verified', user);
          this.search(false);
        });
      }
    });
  }

  public goToReconTab(company: any) {
    this.router.navigate([`reconciliation/company/${company.id}`]);
  }

  public goToCustomerSettings(company: any) {
    if (this.filterForm) {
      this.sessionStorageService.store('settingsCompany', company);
      this.sessionStorageService.store('csSearchParams', {
        filters: this.filterForm.value,
        page: this.currentPage,
      });
      this.router.navigate(['users/settings']);
    }
  }

  public getSupportedMachoolCarriersPopover(company: Company): string {
    const companyMachoolPickupProviders = _.get(company, 'settings.machoolPickupProviders') || [];

    return companyMachoolPickupProviders
      .map((carrier) => {
        if ([ShippingProviderNames.RIVO].includes(carrier)) {
          return ShippingProviderNames.AIR_CANADA_DOMESTIC;
        } else {
          return carrier;
        }
      })
      .join(', ');
  }

  public getMachoolPickupProviderCircleClass(provider: ShippingProviderNames): string {
    switch (provider) {
      case ShippingProviderNames.RIVO:
      case ShippingProviderNames.AIR_CANADA_DOMESTIC:
        return 'AirCanadaDomestic';
      case ShippingProviderNames.AIR_CANADA_INTERNATIONAL:
        return 'AirCanadaInternational';
      default:
        return provider;
    }
  }

  private createForm() {
    this.filterForm = this.formBuilder.group({
      email: ['', []],
      firstName: ['', []],
      lastName: ['', []],
      companyName: ['', []],
      role: ['', []],
      pricingTier: ['', []],
      billingType: ['', []],
      machoolPickup: ['', []],
      salesRepId: ['', []],
      fraudulent: ['', []],
    });

    this.filterForm.setValidators(atLeastOneRequired([]));
  }

  private cleanUsers(): void {
    this.users.forEach((user) => {
      user.companyId = user.company.id;
      user.roleVerbose = this.helpersService.convertRolesEnumToString(user.role);
    });
  }

  private setupGroupedUsers(): void {
    const companies = _.uniqBy(_.map(this.users, 'company'), 'id');
    this.usersByCompany = [];
    _.each(companies, (company) => {
      const companyUsers = this.users.filter((user) => user.company.id === company.id);

      const clonedCompany = _.clone(company);
      clonedCompany.owner = _.get(clonedCompany, 'owner.firstName')
        ? clonedCompany.owner
        : _.filter(companyUsers, function (user) {
            return user.role === Roles.OWNER || user.role === Roles.MACHOOL;
          })[0];

      this.usersByCompany.push({
        company: clonedCompany,
        users: companyUsers,
        companyName: company.name,
        owner: <User>clonedCompany.owner,
        billingType: this.companyService.getBillingType(company),
        billingTypeVerbose: this.companyService.getBillingTypeVerbose(company),
        hasParentCompany: company.hasParentCompany,
        parentCompanyName: company.hasParentCompany && company.parentCompanyName ? company.parentCompanyName : '',
        tier: company.pricingTier,
        tierName: this.helpersService.convertPricingTiersEnumToString(company.pricingTier),
        numUsers: companyUsers.length,
        isFraudulent: this.companyService.isAccountFraudulent(company),
      });
    });
    this.logger.log('Customer Success Lookup - Accounts grouped by company', this.usersByCompany);
  }
  public pageChanged(pageEvent: PageEvent): void {
    this.itemsPerPage = pageEvent.pageSize;
    this.currentPage = pageEvent.pageIndex;
    const end = this.itemsPerPage * (this.currentPage + 1);
    const start = end - this.itemsPerPage;
    this.paginatedUsers = this.users.slice(start, end);
    this.paginatedUsersByCompany = this.usersByCompany.slice(start, end);
  }
}
