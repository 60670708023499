import { NgModule } from '@angular/core';
import { UsersComponent } from './users.component';
import { RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { SettingsComponent } from './settings/settings.component';
import { SharedModule } from '@app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { PaymentTypeComponent } from '@app/pages/authenticated/pages/users/settings/components/payment-type/payment-type.component';
import { NotesComponent } from '@app/pages/authenticated/pages/users/settings/components/notes/notes.component';
import { PickupComponent } from '@app/pages/authenticated/pages/users/settings/components/pickup/pickup.component';
import { TierComponent } from '@app/pages/authenticated/pages/users/settings/components/tier/tier.component';
import { UpdateNoteComponent } from '@app/pages/authenticated/pages/users/settings/components/notes/update-note/update-note.component';
import { CouriersComponent } from '@app/pages/authenticated/pages/users/settings/components/couriers/couriers.component';
import { ScopeGuard } from '@app/shared/guards/scope.guard';
import { AdminScopes } from '@app/shared/services/user.service';
import { SalesComponent } from '@app/pages/authenticated/pages/users/settings/components/sales/sales.component';
import { ComponentsModule } from '@app/shared/components/components.module';
import { CourierModifiersComponent } from '@app/pages/authenticated/pages/users/settings/components/courier-modifiers/courier-modifiers.component';
import { UpdateCourierModifierComponent } from '@app/pages/authenticated/pages/users/settings/components/courier-modifiers/update-courier-modifier/update-courier-modifier.component';
import { DynamicPricingComponent } from '@app/pages/authenticated/pages/users/settings/components/dynamic-pricing/dynamic-pricing.component';
import { UpdateDynamicPricingComponent } from '@app/pages/authenticated/pages/users/settings/components/dynamic-pricing/update-dynamic-pricing/update-dynamic-pricing.component';
import { BillingInfoComponent } from './settings/components/billing-info/billing-info.component';
import { WalletTransactionsComponent } from '@app/pages/authenticated/pages/users/settings/components/wallet-transactions/wallet-transactions.component';
import { AddTransactionComponent } from '@app/pages/authenticated/pages/users/settings/components/wallet-transactions/add-transaction/add-transaction.component';
import { CompanyChargesComponent } from '@app/pages/authenticated/pages/users/settings/components/company-charges/company-charges.component';
import { CompanySettingsComponent } from '@app/pages/authenticated/pages/users/settings/components/company-settings/company-settings.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, ScopeGuard],
    data: { scopes: [AdminScopes.VIEW_USERS] },
    canActivateChild: [ScopeGuard],
    children: [
      {
        path: '',
        component: UsersComponent,
        data: {
          scopes: [AdminScopes.VIEW_USERS],
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
      {
        path: 'settings',
        component: SettingsComponent,
        data: {
          scopes: [AdminScopes.VIEW_USERS_SETTINGS],
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
    ],
  },
];

@NgModule({
  imports: [ComponentsModule, CommonModule, RouterModule.forChild(routes), SharedModule],
  exports: [RouterModule],
  declarations: [
    SettingsComponent,
    UsersComponent,
    CouriersComponent,
    CourierModifiersComponent,
    DynamicPricingComponent,
    NotesComponent,
    PaymentTypeComponent,
    PickupComponent,
    TierComponent,
    SalesComponent,
    UpdateCourierModifierComponent,
    UpdateDynamicPricingComponent,
    UpdateNoteComponent,
    BillingInfoComponent,
    CompanyChargesComponent,
    AddTransactionComponent,
    WalletTransactionsComponent,
    CompanySettingsComponent,
  ],
})
export class UsersModule {}
