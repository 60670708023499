<form [formGroup]="searchForm">
  <mat-card>
    <mat-card-content class='pb--none'>
      <div class='row'>
        <div class='column--12 column-md--4'>
          <mat-form-field class='full-width'>
            <mat-label>Invoice number</mat-label>
            <input matInput formControlName='invoiceNumber' name='invoiceNumber' value="">
          </mat-form-field>
        </div>
        <div class='column--12 column-md--4'>
          <mat-form-field class='full-width'>
            <mat-label>Date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker">
              <input matStartDate formControlName="start" placeholder="Start date">
              <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
        </div>
        <div class='column--12 column-md--4 align-right mt--xs'>
          <button mat-button color='primary' type='button' class='mr--md' (click)='reset()' [disabled]="searchForm.invalid || loading">Clear all</button>
          <button mat-flat-button color='primary' type='submit' (click)='search()' [disabled]="searchForm.invalid || loading">Search</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>

<ng-container *ngIf='!loading, else isLoading'>
  <mat-card *ngIf="paginatedInvoices.length > 0" class='mt--md mat-elevation-z0 mb--sm'>
    <mat-card-content>

      <div class='responsive-table'>
        <table
          mat-table
          [dataSource]="invoicesDataSet"
          multiTemplateDataRows
          matSort
          matSortActive="createdAt"
          matSortDirection="desc"
          (matSortChange)="sortData($event)"
        >
          <ng-container matColumnDef="invoiceNumber">
            <th mat-header-cell *matHeaderCellDef style='width: 15%' mat-sort-header sortActionDescription="Sort by invoice">Invoice</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              <button
                mat-button
                color='primary'
                class='mdc-small-button text--base align-left'
                (click)="getShipmentsByInvoice(element)"
              >{{ element.invoiceNumber }}</button>
            </td>
          </ng-container>
          <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Company">Company</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.companyName }}
            </td>
          </ng-container>
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef style='width: 11%' mat-sort-header sortActionDescription="Sort by date">Invoice Date</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.formattedDate }}
            </td>
          </ng-container>
          <ng-container matColumnDef="dateRange">
            <th mat-header-cell *matHeaderCellDef style='width: 15%'>Date Range</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.dateRangeString }}
            </td>
          </ng-container>
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef style='width: 15%' mat-sort-header sortActionDescription="Sort by invoice amount">Invoice Amount</th>
            <td mat-cell *matCellDef="let element" class='text--sm'>
              {{ element.amount | currency }}
            </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style='width: 155px; min-width: 155px;' mat-sort-header sortActionDescription="Sort by status">Status</th>
            <td mat-cell *matCellDef="let element" class='text--sm pr--none'>
              <mat-chip
                class="chip-invoice status-{{ element.className }}"
                [matTooltip]="'Outstanding balance: ' + (element.balanceDue | currency)"
                matTooltipPosition="above"
                [matTooltipDisabled]="element.balanceDue && element.balanceDue === 0"
              >{{ element.className === 'processing' ? 'Processing ...' : element.status }}</mat-chip>
              <mat-icon
                *ngIf="element.lastEmailSent"
                class='icon-mail mat-icon-sm text--table-header ml--xs'
                [matTooltip]="element.lastEmailSent"
                matTooltipPosition="above">
              </mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let element; columns: displayedColumns;">
          </tr>
        </table>
      </div>

      <mat-paginator
        (page)="pageChanged($event)"
        [length]='invoicesCount'
        [pageSize]="itemsPerPage"
        [pageSizeOptions]="itemsPerPageOptions"
        [pageIndex]='currentPage'
        [disabled]='invoicesCount <= itemsPerPage'>
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
