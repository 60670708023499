import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Company } from '@app/pages/authenticated/pages/users/users.model';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyService } from '@app/shared/services/company.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { Router } from '@angular/router';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
})
export class CompanySettingsComponent implements OnInit {
  @Input() company: Company | undefined;
  @Input() user: AdminUser | undefined;
  @Output() refreshCompany: EventEmitter<string> = new EventEmitter<string>();

  public loading = true;
  public canEdit: boolean = false;
  public companySettingsForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private userService: UserService,
    private logger: LoggerService,
    private router: Router,
    private bannerNotificationsService: BannerNotificationsService,
  ) {}
  ngOnInit() {
    this.loading = true;
    if (
      this.company &&
      this.user &&
      this.userService.hasAuthScope(this.user, [AdminScopes.VIEW_USERS_SETTINGS_COMPANY_SETTINGS])
    ) {
      this.canEdit = this.userService.hasAuthScope(this.user, [AdminScopes.MODIFY_USERS_SETTINGS_COMPANY_SETTINGS]);
      this.createForm();
    } else {
      this.router.navigate(['users']);
    }
  }

  private createForm() {
    const companySettings = this.company.settings || {};
    this.companySettingsForm = this.formBuilder.group({
      enforceSSO: [this.company.enforceSSO || false],
      isLD: [companySettings.isLD || false],
      taxExempt: [companySettings.taxExempt || false],
      addBlindMarginAllowed: [companySettings.addBlindMarginAllowed || false],
      showCustomerReceipt: [companySettings.showCustomerReceipt || false],
      isFraudulent: [companySettings.isFraudulent || false],
    });
    this.loading = false;
  }

  public submit() {
    if (this.canEdit && !this.companySettingsForm.invalid) {
      const updatedSettings = this.companySettingsForm.value;
      const updatedSSOSetting = this.companySettingsForm.value.enforceSSO;
      _.unset(updatedSettings, 'enforceSSO');
      const updateObj = {
        enforceSSO: updatedSSOSetting,
        settings: _.extend(this.company.settings, updatedSettings),
      };

      this.companyService.update(this.company.uuid, updateObj).subscribe(
        (response: any) => {
          this.refreshCompany.emit();
          this.logger.log('UPDATE Company Settings', response);
          this.bannerNotificationsService.success('Company settings flags have been updated');
        },
        (err: Error) => {
          this.bannerNotificationsService.error(
            `There was a problem updating the company settings flags. ${_.get(err, 'error.message', '')}`,
          );
          this.logger.error('UPDATE Company Settings error', err);
        },
      );
    }
  }
}
