<ng-container *ngIf='!loading && filterForm, else isLoading'>
  <div class='container ml--none
'>
    <form [formGroup]="filterForm">
      <mat-card>
        <mat-card-content>
          <div class='row'>
            <div class='column--12 column-md--4'>
              <mat-form-field class='full-width'>
                <mat-label>Company name</mat-label>
                <input matInput formControlName="companyName" name="companyName" value="">
              </mat-form-field>
            </div>
            <div class='column--6 column-md--2'>
              <mat-form-field class='full-width'>
                <mat-label>First name</mat-label>
                <input matInput formControlName='firstName' name='firstName' value="">
              </mat-form-field>
            </div>
            <div class='column--6 column-md--2'>
              <mat-form-field class='full-width'>
                <mat-label>Last name</mat-label>
                <input matInput formControlName='lastName' name='lastName' value="">
              </mat-form-field>
            </div>
            <div class='column--12 column-md--4'>
              <mat-form-field class='full-width'>
                <mat-label>Email address</mat-label>
                <input matInput formControlName='email' name='email' value="">
              </mat-form-field>
            </div>
          </div>

          <mat-accordion>
            <mat-expansion-panel class='mat-expansion-panel-flat mat-elevation-z0' [expanded]='isExpanded'>
              <div class='row'>
                <div class='column--12 column-md--4'>
                  <mat-form-field class='full-width'>
                    <mat-label>Pricing tier</mat-label>
                    <mat-select formControlName="pricingTier" name='pricingTier'>
                      <mat-option *ngFor='let option of pricingTiersDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class='column--12 column-md--4'>
                  <mat-form-field class='full-width'>
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="role" name='role'>
                      <mat-option *ngFor='let option of userRolesDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class='column--12 column-md--4'>
                  <mat-form-field class='full-width'>
                    <mat-label>Billing type</mat-label>
                    <mat-select formControlName="billingType" name='billingType'>
                      <mat-option *ngFor='let option of billingTypesDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class='row pt--sm'>
                <div class='column--12 column-md--4'>
                  <mat-form-field class='full-width'>
                    <mat-label>Sales</mat-label>
                    <mat-select formControlName="salesRepId" name='salesRepId'>
                      <mat-option *ngFor='let option of salesRepsDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class='column--12 column-md--4 with-gutter'>
                  <mat-form-field class='full-width'>
                    <mat-label>Fraudulent</mat-label>
                    <mat-select formControlName="fraudulent" name='fraudulent'>
                      <mat-option *ngFor='let option of fraudulentDropdown' value="{{ option.id }}">{{ option.text }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <div class='row'>
            <div class='column--12 column-md--6'>
              <button mat-button color='primary' style='margin-right: 15px;' type='button' (click)='togglePanel()'>
                <span *ngIf='!isExpanded'>Show more criteria</span>
                <span *ngIf='isExpanded'>Show less criteria</span>
              </button>
            </div>
            <div class='column--12 column-md--6 align-right'>
              <button mat-button color='primary' type='button' class='mr--md' (click)='reset()' [disabled]="filterForm.invalid || loading">Clear all</button>
              <button mat-flat-button color='primary' type='submit' (click)='search()' [disabled]="filterForm.invalid || loading">Search</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
    <mat-card *ngIf="!loading && paginatedUsers.length > 0" class='mt--lg mat-elevation-z0'>
      <mat-card-content>

        <div class='responsive-table'>
          <table mat-table [dataSource]="paginatedUsersByCompany" multiTemplateDataRows style='min-width: 1115px;'>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef style='width: 224px'>Name</th>
              <td mat-cell *matCellDef="let element">
                <button *ngIf='canViewSettings' mat-button color='primary' class='mdc-small-button text--base align-left' (click)="goToCustomerSettings(element.company)">{{ element.companyName && element.companyName.length > 0 ? element.companyName : element.owner.firstName + ' ' + element.owner.lastName }}</button>
                <span *ngIf='!canViewSettings'>{{ element.companyName && element.companyName.length > 0 ? element.companyName : element.owner.firstName + ' ' + element.owner.lastName }}</span>
                <span>
                  <mat-icon
                    class='icon-noservice text--danger text--sm text--base'
                    *ngIf='element.isFraudulent'
                    matTooltip="Account Suspended"
                    matTooltipPosition="above"
                    [ngStyle]="{'position': 'relative', 'top': '12px'}">
                  </mat-icon>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="users">
              <th mat-header-cell *matHeaderCellDef style='width: 118px'>Users</th>
              <td mat-cell *matCellDef="let element">
                {{ element.numUsers }}
              </td>
            </ng-container>
            <ng-container matColumnDef="tier">
              <th mat-header-cell *matHeaderCellDef style='width: 150px'><ng-container *ngIf='canViewExtendedInfo'>Tier</ng-container></th>
              <td mat-cell *matCellDef="let element">
                <mat-chip *ngIf='canViewExtendedInfo' class="chip-tier tier-{{element.tier}}">{{ element.tierName }} / {{element.company.pricingMargin}}</mat-chip>
              </td>
            </ng-container>
            <ng-container matColumnDef="pickup">
              <th mat-header-cell *matHeaderCellDef style='width: 204px'><ng-container *ngIf='canViewExtendedInfo'>Pickup</ng-container></th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="canViewExtendedInfo && element.company.settings && (element.company.settings.machoolPickupProviders || []).length > 0">
                  <span
                    *ngFor="let provider of element.company.settings.machoolPickupProviders"
                    matTooltip="Supported carriers: {{ getSupportedMachoolCarriersPopover(element.company) }}"
                    matTooltipPosition="above"
                    class="circle {{ getMachoolPickupProviderCircleClass(provider) }}"></span>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="billingType">
              <th mat-header-cell *matHeaderCellDef style='width: 245px'><ng-container *ngIf='canViewExtendedInfo'>Payment Type / Role</ng-container></th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf='canViewExtendedInfo'>
                  <ng-container *ngIf='element.hasParentCompany, else regularCompanyBilling'>
                    <span>Billed to: {{ element.parentCompanyName }}</span>
                  </ng-container>
                  <ng-template #regularCompanyBilling>
                    <span *ngIf="element.billingType !== 'M'">{{ element.billingTypeVerbose }}</span>
                    <span
                      *ngIf="element.billingType === 'M'"
                      matTooltip="Credit limit: {{ element.company.billingLimit }}"
                      matTooltipPosition="above"
                    >{{ element.billingTypeVerbose }}</span>
                    <mat-icon
                      *ngIf="element.company.shippingDisabled"
                      class='icon-noservice text--danger text--sm'
                      matTooltip="Shipping is disabled"
                      matTooltipPosition="above"
                    ></mat-icon>
                  </ng-template>
                </ng-container>
              </td>
            </ng-container>

            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
              <td mat-cell *matCellDef="let element"  class='align-right'>
                <button
                  *ngIf='canViewRecon'
                  mat-button
                  color='primary'
                  type='button'
                  matTooltip="Go to Reconciliation"
                  matTooltipPosition="above"
                  (click)='goToReconTab(element.company)'
                >
                  <mat-icon class='icon-arrow-circle-right'></mat-icon>
                </button>
              </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="expanded-detail mat-elevation-z0">
                  <table mat-table [dataSource]='element.users' class='pt--sm mb--md'>
                    <ng-container matColumnDef="customerName">
                      <td mat-cell *matCellDef="let user" class='text--sm' style='width: 200px'>
                        {{ user.firstName + ' ' + user.lastName}}
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="email">
                      <td mat-cell *matCellDef="let user" class='text--sm' style='width: 480px'>
                        <span *ngIf="!canEdit || user.isConfirmed else unconfirmed" style='padding-left: 8px'>{{ user.email }}</span>
                        <ng-template #unconfirmed>
                          <button
                            mat-button
                            color='primary'
                            class='mdc-small-button text--base'
                            type='button'
                            matTooltip="Account not verified"
                            matTooltipPosition="above"
                            (click)="verifyAccount(user)"
                          >
                            {{ user.email }}
                            <mat-icon iconPositionEnd class='icon-no ml-1' title='Account not verified'></mat-icon>
                          </button>
                        </ng-template>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="role">
                      <td mat-cell *matCellDef="let user" class='text--sm' style='width: 242px'>
                        <ng-container *ngIf='canViewExtendedInfo'>{{ user.roleVerbose}}</ng-container>
                      </td>
                    </ng-container>
                    <ng-container matColumnDef="login">
                      <td mat-cell *matCellDef="let user" class='text--sm'>
                        <button
                          *ngIf='canViewLogin'
                          mat-button
                          color='primary'
                          class='mdc-small-button text--sm'
                          type='button'
                          (click)="loginAs(user)"
                        >Login</button>
                      </td>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: displayedExpandedColumns;"></tr>
                  </table>
                </div>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expansion-row"></tr>
          </table>
        </div>
        <mat-paginator
          (page)="pageChanged($event)"
          [length]='usersByCompany.length'
          [pageSize]="itemsPerPage"
          [pageSizeOptions]="itemsPerPageOptions"
          [pageIndex]='currentPage'
          [disabled]='usersByCompany.length <= itemsPerPage'
        >
        </mat-paginator>
      </mat-card-content>
    </mat-card>
    <ng-container *ngIf='!loading && paginatedUsers.length === 0 && noUsersReturned'>
      <div class='row mt--xl'>
        <div class='column--12 align-center'>
          <div style='width: 220px; margin: 0 auto;'>
            <img src='{{ CDN_URL }}/admin/images/empty-user_animated.svg' />
            <p>No users found</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
