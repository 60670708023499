<ng-container *ngIf='!loading, else isLoading'>
  <div class='container'>
    <ng-container *ngIf="!globalConfiguration, else showGlobalConfiguration">
      <div class='row mt--md'>
        <div class='column--12 column-md--4 align-center mx--auto'>
          <img src='{{ CDN_URL }}/admin/images/empty_animated.svg' />
          <p>No global configuration found</p>
        </div>
      </div>
    </ng-container>
    <ng-template #showGlobalConfiguration>
      <div class='row mt--md'>
        <div class='column--4'>
          <form [formGroup]="globalConfigurationForm" novalidate>
            <mat-form-field class='column--12'>
              <mat-label>BYOA Account Charge ($)</mat-label>
              <mat-icon matPrefix>attach_money</mat-icon>
              <input matInput type='number' formControlName='byoaChargeAmount' name='byoaChargeAmount' placeholder='0.00'>
            </mat-form-field>
          </form>
        </div>

      </div>
      <div class='row'>
        <div class='column--12 mt--md'>
          <button mat-flat-button color='primary' type='button' (click)="updateGlobalConfiguration()" [disabled]="!canEdit || loading || globalConfigurationForm.invalid">
            Update
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
